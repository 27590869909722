<template>
	<modal ref="modalOrderCedis" titulo="Ordenar supercedis" no-adicional no-aceptar no-cancelar btnCancelar="Cerrar">
		<div class="row mx-0 align-items-center">
			<div class="col-12 text-center">
				<draggable :list="superCedisList" handle=".handle" @change="changeItemsPositions">
					<div v-for="(data, idx) in superCedisList" :key="idx" class="row mx-0 my-3 align-items-center">
						<div class="num d-middle-center fr-light wh-36 rounded-circle text-white bg-general2 f-20">
							{{ idx + 1 }}
						</div>
						<i class="icon-drag f-22 cr-pointer handle text-muted mx-2" />
						<img :src="data.img" class="br-4 wh-36 border mx-2" alt />
						{{ data.nombre }}
					</div>
				</draggable>
			</div>
		</div>
	</modal>
</template>

<script>
import Service from '~/services/cedis/super_cedis'


export default {
	props: {
		superCedisList: {
			type: Array,
			required: true,
			default: () => []
		},
	},
	data() {
		return {
			idCedi: parseInt(this.$route.params.id_cedis),
		}
	},
	methods: {
		toggle() {
			this.$refs.modalOrderCedis.toggle();
		},
		async changeItemsPositions(event) {
			try {
				const payload = {
					idsItems: _.map(this.superCedisList, 'id_cedis_hijo'),
					idCedis: this.idCedi,
				};
				const { data } = await Service.updatePosicionesHijos(payload)
				this.notificacion('Éxito', data.mensaje, 'success')
				// this.$refs.modalOrderCedis.toggle();
			} catch (error) {
				return this.error_catch(error);
			}
		}
	}
}
</script>
