<template>
	<div
		:class="`${idCedi === cedis.id_cedis_hijo ? 'mine' : ''} card-cedis-local d-middle-center position-relative border br-8 wf-145px h-185px`">
		<div class="text-center px-2">
			<div class="wh-53 position-relative mx-auto">
				<div v-if="cedis.stock === 1 || cedis.stock === 2"
					class="ind-stock wh-32 rounded-circle d-middle-center position-absolute"
					:class="`${cedis.stock === 2 ? 'bg-general2' : 'bg-green'}`">
					<i
						:class="`${cedis.stock === 2 ? 'icon-package-variant-closed' : 'icon-package-variant'} text-white f-24`" />
				</div>
				<img :src="cedis.img" width="53" height="53" class="br-4" alt />
			</div>
			<p class="text-general mt-1 wM-120px tres-puntos">{{ cedis.nombre }}</p>
			<div class="d-middle-center tres-puntos">
				<i class="icon-format-list-checks text-general f-20" />
				<p class="f-17 fr-light ml-2 text-general"
					v-if="(tipo === 2 && cedis.cantidad != null) || (tipo === 1 && dataAdicional && dataAdicional.cantidad != null)">
					{{ redondearNumero((tipo === 2 ? cedis.cantidad : dataAdicional.cantidad) || 0) }}
				</p>
				<p v-else class="f-17 fr-light ml-2 text-general">0</p>
			</div>
			<div class="h-35px">
				<div v-if="tipo === 1 && dataAdicional && dataAdicional.estado_destino == 1" class="d-middle">
					<i class="icon-ok-circled-outline mr-2" />
					<div class="flex-fill f-14 f-light lh-17 text-left tres-puntos">
						<p class="text-general tres-puntos wM-90px">{{ dataAdicional.user_recibio }}</p>
					</div>
				</div>
				<button v-if="tipo == 1 && dataAdicional && dataAdicional.estado_destino == 0"
					class="btn text-white f-14 br-8 wf-108px bg-green" @click="updateEstadoTraslado">
					{{ 'Recibir' }}
				</button>
				<button v-if="tipo == 2 && idCedi === cedis.id_cedis_hijo"
					class="btn text-white f-14 br-8 wf-108px bg-alert" @click="updateEstadoTraslado">
					{{ 'Entregar' }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Traslados from '~/services/cedis/supercedis_traslados'

export default {
	props: {
		cedis: {
			type: Object,
			required: false,
			default: () => { }
		},
		dataAdicional: {
			type: Object,
			required: false,
			default: () => { }
		},
		tipo: {
			type: Number,
			required: true,
			default: 1
		},
	},
	data() {
		return {
			idCedi: parseInt(this.$route.params.id_cedis),
		}
	},
	methods: {
		async updateEstadoTraslado() {
			try {
				const payload = {
					idAdminPedido: this.dataAdicional.id_admin_pedido,
					idProducto: this.dataAdicional.id_producto,
					idCedisDestino: this.dataAdicional.id_cedis_destino,
					idCedisOrigen: this.dataAdicional.id_cedis_origen,
					tipo: this.tipo
				}
				const { data } = await Traslados.updateEstadoTraslado(payload);
				this.notificacion('Éxito', data.mensaje, "success")
				if(this.tipo == 1){
					this.dataAdicional.estado_destino = 1;
					this.dataAdicional.user_recibio = data.data;
				}else{
					this.$emit('entregado')
				}
			} catch (error) {
				return this.error_catch(error);
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.card-cedis-local {
	.ind-stock {
		top: -14px;
		right: -17px;
	}

	.btn-recibir {
		background-color: #28D07B;
	}

	&.mine {
		background: #F6F9FB;
		border: 1px solid transparent !important
	}

	.icon-ok-circled-outline {
		color: #28D07B;
	}
}
</style>