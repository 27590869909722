import axios from 'axios'

const api = "super-cedis"

const ENDPOINTS = {

    getCedisSelect(params){
        return axios.get(`${api}/cedis-select`,{params})
    },
	getCedisHijos(idCedis){
		return axios.get(`${api}/${idCedis}/cedis-hijos`)
	},
	agregarCedisHijo(payload){
		return axios.post(`${api}`, payload)
	},
	updatePosicionesHijos(payload){
		return axios.put(`${api}/update-posiciones`, payload)
	},
	removeCedisHijo(idCedisHijo){
		return axios.delete(`${api}/${idCedisHijo}/remover`)
	}
}

export default ENDPOINTS;