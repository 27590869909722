<template>
    <div class="card-super-cedis d-middle position-relative border br-12 bg-white wf-223px">
        <div class="num d-middle-center fr-light wh-36 rounded-circle text-white bg-general2 f-20">
            {{ index+1 }}
        </div>
        <img :src="cedis.img" class="br-4 wh-36 border" alt />
        <p class="f-15 text-general ml-2 tres-puntos">{{ cedis.nombre }}</p>
        <i class="icon-delete-outline ml-auto f-20 mr-1 cr-pointer" @click="$emit('deleteSuperCedis')" />
    </div>
</template>
<script>
export default {
	 props:{
        index : {
            type: Number,
            default: 0
        },
        cedis : {
            type: Object,
            required: true,
            default: () => {}
        },
    },
    data(){
        return {
        }
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.card-super-cedis{
	padding: 5px 0px;
	.num{
		position: relative;
    	right: 15px;
	}
}
</style>